// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-js": () => import("./../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-shop-cart-js": () => import("./../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-index-js": () => import("./../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-thank-you-js": () => import("./../src/pages/shop/thank-you.js" /* webpackChunkName: "component---src-pages-shop-thank-you-js" */),
  "component---src-templates-news-item-js": () => import("./../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-serial-js": () => import("./../src/templates/serial.js" /* webpackChunkName: "component---src-templates-serial-js" */),
  "component---src-templates-serial-summary-js": () => import("./../src/templates/serialSummary.js" /* webpackChunkName: "component---src-templates-serial-summary-js" */),
  "component---src-templates-serials-or-stories-js": () => import("./../src/templates/serials-or-stories.js" /* webpackChunkName: "component---src-templates-serials-or-stories-js" */),
  "component---src-templates-shop-collection-page-js": () => import("./../src/templates/shop/collection-page.js" /* webpackChunkName: "component---src-templates-shop-collection-page-js" */),
  "component---src-templates-shop-product-page-js": () => import("./../src/templates/shop/product-page.js" /* webpackChunkName: "component---src-templates-shop-product-page-js" */),
  "component---src-templates-story-js": () => import("./../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-story-summary-js": () => import("./../src/templates/storySummary.js" /* webpackChunkName: "component---src-templates-story-summary-js" */),
  "component---src-templates-works-js": () => import("./../src/templates/works.js" /* webpackChunkName: "component---src-templates-works-js" */)
}

