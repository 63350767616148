import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const TitleBanner = ({fullWidth}) => {
  const data = useStaticQuery(graphql`
    query {
      fullBanner: file(relativePath: { eq: "hicksville-title.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      medBanner: file(relativePath: { eq: "hicksville-title-thinner.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <header
      className={(fullWidth === true)
        ? 'w-11/12'
        : 'w-full m-auto mb-4'}
    >
      <Link to='/' className='no-underline'>
        <Img
          fluid={
            fullWidth
              ? data.fullBanner.childImageSharp.fluid
              : data.medBanner.childImageSharp.fluid
          }
          alt='handdrawn lettering saying Hicksville'
          className='w-full'
        />
      </Link>
    </header>
  );
};

export default TitleBanner;
