/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/aesthetic/global.css"
require('typeface-fira-sans')
require('typeface-ibm-plex-sans')
require('typeface-ibm-plex-mono')
// You can delete this file if you're not using it
