import React, { useContext } from 'react';
import { reduce } from 'lodash';
import { Link } from 'gatsby';
import StoreContext from '../../context/store-context.js';

const useQuantity = () => {
  const { store: { checkout } } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return [total !== 0, total];
};

const CartCounter = () => {
  const [hasItems, quantity] = useQuantity();

  return (
    <>
      {hasItems &&
        <Link to='/shop/cart'>
          <p className='fixed top-0 right-0 bg-yellow-400 m-3'>cart: {quantity}</p>
        </Link>}
    </>
  );
};

export default CartCounter;
