import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Footer = ({ home }) => {
  const data = useStaticQuery(graphql`
  query {
    beginning: file(relativePath: { eq: "footer-beginning.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    home: file(relativePath: { eq: "footer-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    creativeCommons: file(relativePath: {eq: "creativecommons.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    press: file(relativePath: { eq: "hicksville-press.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    about: file(relativePath: { eq: "footer-about.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000){
          ...GatsbyImageSharpFluid
        }
      }
    }
    works: file(relativePath: { eq: "footer-works.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    store: file(relativePath: { eq: "footer-store.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    other: file(relativePath: { eq: "footer-other.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    end: file(relativePath: { eq: "footer-end.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);

  return(
    <footer
      className="max-w-4xl m-auto text-center py-5">
      {!home &&
      <nav className='w-full flex justify-center items-end mb-2 flex-wrap md:flex-no-wrap'>
          <Img
            className='w-1/4 md:w-1/6 mb-2'
            fluid={data.beginning.childImageSharp.fluid}
            alt='handdrawn twitter icon'/>
        <Link to='/works'
          className='w-1/4 md:w-1/6 mb-2' >
          <Img
            className='w-full'
            fluid={data.works.childImageSharp.fluid}
            alt='handdrawn twitter icon'/>
        </Link>
        <Link to='/about'
              className='w-1/4 md:w-1/6 mb-2' >
          <Img
            className='w-full'
            fluid={data.about.childImageSharp.fluid}
            alt='handdrawn twitter icon'/>
        </Link>
        <Img
          className='w-1/4 md:hidden mb-2'
          fluid={data.end.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
        <Img
          className='w-1/4 md:hidden mb-2'
          fluid={data.beginning.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
        <Link to='/shop'
              className='w-1/4 md:w-1/6 mb-2' >
          <Img
            className='w-full'
            fluid={data.store.childImageSharp.fluid}
            alt='handdrawn twitter icon'/>
        </Link>
        <Link to='/other'
              className='w-1/4 md:w-1/6 mb-2' >
          <Img
            className='w-full'
            fluid={data.other.childImageSharp.fluid}
            alt='handdrawn twitter icon'/>
        </Link>
        <Img
          className='w-1/4 md:hidden mb-2'
          fluid={data.end.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
        <Img
          className='w-1/4 md:hidden mb-2'
          fluid={data.beginning.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
        <a href='https://hicksvillepress.com'
           target="_blank"
           rel="noreferrer noopener"
           className='w-1/2 md:w-1/3 mb-2' >
          <Img
            className='w-full'
            fluid={data.press.childImageSharp.fluid}
            alt='handdrawn twitter icon'/>
        </a>
        <Img
          className='w-1/4 md:w-1/6 mb-2'
          fluid={data.end.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
      </nav>}
      {!home &&
    <div id='home' className='w-full md:w-1/2 flex justify-center content-end m-auto mb-4'>
      <Img
        className='w-1/3 mb-2'
        fluid={data.beginning.childImageSharp.fluid}
        alt='handdrawn twitter icon'/>
      <Link to='/'
            className='w-1/3 md:w-1/3 mb-2' >
        <Img
          className='w-full'
          fluid={data.home.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
      </Link>
      <Img
        className='w-1/3 mb-2'
        fluid={data.end.childImageSharp.fluid}
        alt='handdrawn twitter icon'/>
    </div> }
      <a rel="license"
         href="http://creativecommons.org/licenses/by-nc/3.0/nz/">
        <Img
          className='m-auto w-32'
          alt="Creative Commons License"
          fluid={data.creativeCommons.childImageSharp.fluid}/>
      </a>
      <p className='py-2'>All comics are by Dylan Horrocks and are licensed under a Creative Commons License.</p>
    </footer>
  )
};

export default Footer;
